import { AppRoot, MainLogo } from '@vadato/component';
import moment from 'moment';
import 'moment/locale/fr'
import React, { useEffect, useState } from 'react';
import { VadatoRoutes } from './page/VadatoRoutes'

const App: React.FC = () => {

    useEffect(() =>{
        console.log(moment.locale('fr'))
    }, [])

    return (
        <AppRoot
            routes={VadatoRoutes}
            initialPath="donations"
            suspenseFallback={<MainLogo img={'/logo.PNG'} />}
            protectedRoutes="*"
            loginPath='/auth/login'  
            unloggedRoutes={[
                "/auth/login/forgot-password"
            ]}
            />
    );
}

export default App;

